<template>
  <div class="member-row">
    <div class="member-row-text-field">
      {{name}}
    </div>
    <div class="member-row-text-field">
      {{status}}
    </div>
    <div class="member-row-text-field">
      {{ monthConst + '/' + dayConst + '/' + yearConst }}
    </div>
    <div class="member-row-text-field">
      {{'$'+amount/100}}
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'name',
    'createdDate',
    'status',
    'amount',
  ],
  components: {
  },
  data() {
    return {
      isCheckboxChecked: false,
      isActionMenuShown: false,
      dayConst: ("0" + new Date(this.createdDate*1000).getUTCDate()).slice(-2),
      monthConst: ("0" +(new Date(this.createdDate*1000).getMonth() + 1)).slice(-2),
      yearConst: new Date(this.createdDate*1000).getFullYear(),
    }
  },
  computed: {
  },
  created() {
  },
  watch: {
    selectedRows(newValue, _) {
      if (newValue.includes(this.id)) this.isCheckboxChecked = true
      else this.isCheckboxChecked = false
    },
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';
.member-row {
  width: calc(100% - 80px) !important;
  height: 44px;
  padding: 0 40px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  font-size: $fontSizeStandart;
  color: $fontColorBlack;

  .member-row-text-field {
    width: 150px;
    min-width: 150px;
    height: 20px;
    margin-left: 34px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }
}
</style>
