<template>
  <MainHeader>
    <div class="header-row">
      <div class="title-section">
        <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
        <div class="title-container">
          <div class="title">Billing</div>
          <div class="current-date">{{getCurrentDate()}}</div>
        </div>
      </div>
    </div>
    <div class="header-row">
      <div class="left-side-actions">
        <SearchField
          @search ="handleSearch($event)"
          @cancelSearch="handleSearch($event)"
        />
      </div>
    </div>
  </MainHeader>
  <MainCard>
    <CardTableHeader
      :isCheckboxShown="false"
      :isNameSortArrowHiddent="true"
      :isBillingAmountShown="true"
      :isBillingDateShown="true"
      :isMembersStatusShown="true"
      :isActionHeaderHiddent="true"
      :order="order"
    />
    <DividerLine/>
    <div class="billing-list" v-if="currentPage.length && !errorMessage && !isLoading">
      <BillingCardRow
        v-for="billing in currentPage"
        :key="billing.id"
        :id="billing.id"
        :name="billing.member_name"
        :status="billing.status"
        :createdDate="billing.created"
        :amount="billing.amount"

      />
    </div>
      <div class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <Spinner v-if="isLoading" />
      <DividerLine />
      <CardFooter
        :currentPageNumber="currentPageNumber"
        :totalPages="toPages"
        :adminBillingPageNumberShown="true"
        :currentItemsCount="`${fromItems}-${toItems}`"
        :totalItemsCount="totalItemsCount"
        :RowsPerPage="RowsPerPage"
        @setCurrentPerPage="setCurrentPerPage($event)"
        @showPrevPage="getPrevPage"
        @showNextPage="getNextPage"
      />
  </MainCard>
</template>

<script>
import { mapState, mapActions} from 'vuex'

import MainHeader from '../../common/header/MainHeader.vue'
import MainCard from '../../common/mainContent/MainCard.vue'
import CardTableHeader from '../../common/mainContent/CardTableHeader.vue'
import DividerLine from '../../common/DividerLine.vue'
import CardFooter from '../../common/mainContent/CardFooter.vue'
import UndoRedoBtns from '../../common/header/UndoRedoBtns.vue'
import ActionMenu from '../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../common/actionMenu/ActionMenuItem.vue'
import Spinner from '../../common/Spinner.vue'
import ActionButton from '../../common/ActionButton.vue'
import SearchField from '../../common/header/SearchField.vue'
import MoreActionsBtn from '../../common/MoreActionsBtn.vue'
import BillingCardRow from "@/components/admin/adminBilling/BillingCardRow";

import { getCurrentDate } from '../../../utils/helpers'
export default {
  components: {
    MainHeader,
    MainCard,
    CardTableHeader,
    DividerLine,
    CardFooter,
    UndoRedoBtns,
    ActionMenu,
    ActionMenuItem,
    Spinner,
    ActionButton,
    SearchField,
    MoreActionsBtn,
    BillingCardRow,

  },
  emits: ['openSidebar'],
  data() {
    return {
      newSortType:'All',
      nextPageNumber: null,
      isLoading: false,
      searchTerm: '',
      pageSize: 10,
      currentPageNumber: 1,
      selectedRows: [],
      isModalShown: false,
      errorMessage: '',
      order: '',
      isSortDropdownOpened: false,
      RowsPerPage: [10,20,50],
    }
  },
  async created() {
    await this.getFirstPage()
  },
  computed: {
    ...mapState([
      'billingAdminHistory',
    ]),
    billingHistoryList() {
      return this.billingAdminHistory.billingList
    },
    currentPage() {
      const start = this.currentPageNumber === 1
        ? 0
        : this.currentPageNumber * this.pageSize - this.pageSize

      const stop = this.currentPageNumber === 1
        ? this.pageSize
        : this.currentPageNumber * this.pageSize

      const page = this.billingHistoryList.slice(start, stop)

      return page
    },
    totalItemsCount() {
      return this.billingAdminHistory.billingCount
    },
    toPages() {
      if (this.totalItemsCount< this.pageSize) return  1
      return Math.ceil(this.totalItemsCount/this.pageSize)
    },
    lastId() {
      return this.currentPage.length
        ? this.currentPage.pop().id
        : ''
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toItems() {
      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    }
  },
  watch: {
    modalError(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {
          this.modalError = ''
        }, 3000);
      }
    },
    selectedRows(newValue,oldValue) {
      if (newValue.length >= 1) {
        this.actionActive = true
      } else {
        this.actionActive = false
      }
    },
  },
  methods: {
    ...mapActions([
      'getAdminBillingHistory',
      'searchSubscription',
      'clearAdminBillings',
    ]),
    handleGeneralError(error) {
      console.log('==== error while obtaining pipeline info: ', error);
      this.errorMessage = 'Something went wrong, please try again'
    },
    async getFirstPage() {
      try {
        this.nextPageNumber =  await this.getAdminBillingHistory({ pageSize: this.pageSize, lastId: this.lastId,searchTerm:this.searchTerm})
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage
      await this.getFirstPage()
    },
    async handleSearch(searchValue) {
      this.isLoading = true
      this.searchTerm = searchValue

      const payload = {
        pageNumber: this.nextPageNumber,
        searchTerm: this.searchTerm,
        pageSize: this.pageSize,
      }
      
      try {
        await this.searchSubscription(payload)
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async getNextPage() {
      this.isLoading = true

      try {
        this.nextPageNumber = await this.getAdminBillingHistory({ pageSize: this.pageSize, lastId: this.lastId,searchTerm:this.searchTerm})

        if (!this.nextPageNumber) {
          this.getPrevPage()
        }

        this.currentPageNumber += 1
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.selectedRows = []
      this.currentPageNumber -= 1
    },
    closeSortDropdown() {
      this.isSortDropdownOpened = false
    },
    openSortDropdown() {
      this.isSortDropdownOpened = true
    },
    setNewSortType(sortName){
      this.isSortDropdownOpened = false
      this.newSortType = sortName
    },
    getCurrentDate,
  },
  async beforeUnmount() {
    await this.clearAdminBillings()
  },
}
</script>

<style lang="scss" scoped>
  @import '../../../assets/styleVars.scss';

  .main-card {
    .card-table-header:deep(div:last-of-type){
      margin-left: 30px !important;
    }
  }
  
  .header-row {
    width: 100%;
    height: 50%;
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
    color: $fontColorBlack;

    .sidebar-btn {
      width: 0;
      height: 0;
      position: absolute;
      background-size: 24px;
      background-repeat: no-repeat;
      background-image: url('../../../assets/icons/hamburger-icon.svg');
    }

    .title-section {
      display: flex;
      justify-content: center;
      align-items: center;

      .title-container {
        display: flex;
        flex-direction: column;

        .title {
          font-size: $fontSizeTitle;
        }

        .current-date {
          font-size: $fontSizeMinified;
          color: $fontColorGray;
        }
      }

      .vertical-divider {
        width:1px;
        height: 54px;
        margin-left: 20px;
        top: 35px;
        background: #c9cde8;
      }
    }
    .header-button-section {
      position: relative;
      display: flex;
      .action-button {
        margin-left:12px;
      }
      .action-btn-invite {
        background-color: #E5E5E5;
        border: 1px solid $appActionColor;
        color: $appActionColor;
      }
    }
    .left-side-actions {
      position: relative;
      display: flex;
    }
    .sort-side {
      position: relative;
      display: flex;

      .field-label {
        color: $fontColorBlack;
        font-size: $fontSizeStandart;
        margin-top:15px !important;
      }
      .sort-wrapper {
        width: 170px;
        height: 68px;
        position: relative;
        margin-left: 15px;

        .sort-field {
          width: 170px;
          height: 44px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          position: relative;
          font-size: $fontSizeStandart;
          border: 1px solid $grayBorderColor;
          box-sizing: border-box;
          background-color: white;
          border-radius: 4px;
          cursor: pointer;

          .open-dropdown-btn {
            width: 20px;
            height: 20px;
            top: 12px;
            right: 19px;
            position: absolute;
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
            background-image: url('../../../assets/icons/chevron-down-black-icon.svg');
          }
          .close-dropdown-btn {
            background-image: url('../../../assets/icons/chevron-up-black-icon.svg');
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .header-row {
      align-items: center;

      .sidebar-btn {
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }

      .action-button {
        margin-top: 0;
      }
    }
  }
  @media (max-width: 420px) {
    .header-row {
      .sort-side {
        .field-label {
          margin-top: 0;
        }
        .sort-wrapper {
          height: 44px;

          .sort-field {
            width: 100px;
            height: 44px;
          }
        }
      }
    }
    .card-table-header {
      width: calc(100% - 20px);
    }
    .action-button {
      width: 78px !important;
      font-size: $fontSizeActionButtonMinified;
      padding-left: 5px;
    }
  }
</style>

<style lang="scss">
  @import '../../../assets/styleVars.scss';
  .billing-list {
    width: 100%;
    height: calc(100% - 104px);
    overflow-y: scroll;
  }
  .error-message {
    width: 100%;
    height: calc(100% - 104px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redWarningColor;
  }
</style>
